import React, { useEffect, useState } from 'react'
import Scrollspy from "react-scrollspy"
import Scroll from "../Scroll"
import logo from '../../images/logo/logo_inverse.png'
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import "./Header.scss"

const Header = () => {

    const createNavLink = (identifier, offset = -81) => {
        return (
            <Scroll type="id" element={identifier} offset={offset}>
                <a className="nav-link" href={`#${identifier}`}>
                    <FormattedMessage id={`nav.${identifier}`} />
                </a>
            </Scroll>
        )
    }

    const [scroll, setScroll] = useState(1)
    const [isOpened, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY < 100
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })
    });

    const toggleNav = () => {
        setOpen(!isOpened);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top nav-black" id="mainNav">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" />
                    </Link>
                    <button className="navbar-toggler" onClick={toggleNav} type="button" data-bs-toggle="collapse" data-bs-target="#navBarContent" aria-controls="navBarContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa fa-bars"></i>
                    </button>
                    <div className={`${isOpened ? "" : "collapse "}navbar-collapse`}  id="navBarContent">
                        <Scrollspy
                            className="navbar-nav ml-auto order-0"
                            items={["cookies", "gdpr"]}
                            currentClassName="active"
                            offset={-111}>
                            <li className="nav-item">
                                {createNavLink("gdpr")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("cookies")}
                            </li>
                        </Scrollspy>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header